

// Generic Issue
export const issueSoundsLikeData = [
    "No sound I've noticed, but I'll let you know if I hear anything between now and my appointment",
    "Slapping ( slap-slap-slap)",
    'Whining (Wheeeeeeeeee)',
    'Grinding (grrrrrrrrrr)',
    'Squeaking (Skreeeee)',
    'Other'
];

export const issueData = [
    'happens intermittently',
    'Is getting worse',
    'Seems to happen when the engine is warm',
    'Seems to happen when the engine is cold',
    'Happens when I start the car, but only in the morning',
    'Seems to happen when I turn a certain direction (please describe)',
    'Seems to happen at certain speeds (please describe)',
    "I haven't noticed a pattern, but if I do I'll let you know",
    "I haven't noticed a symptom, another shop just recommended work",
    'Other'
];

export const auxCluesData = [
    'My fuel economy is getting worse/is poor',
    'I have gotten gas shortly before the issue occured',
    'The fluids were changed recently',
    'Other work was recently done to the vehicle',
    'My radio, dash lights, etc. also stopped working',
    'This vehicle is new to me/recently acquired',
    'Other'
]

// Funky Vehicle Notes
export const funkyNotesData = [
    'No Funk',
    'I plan on going on a road trip/camping soon',
    "Please don't roll down windows",
    'The car door has a different key than the ignition',
    'I am also providing a lug nut key',
    'Other',
]

// Cost
export const costExpectData = [
    "An initial diagnosis of ONE issue for $80, two for $160, etc.",
    "Repairs/maintenance work at the normal shop rate of $120/hour",
    "A pre-purchase/85 point inspection for $160 for 2WD, $175 for 4WD, or $200 for a specialty vehicle(eg vanagon, delica)",
    "Other"
]

// Purpose
export const purposeData = [
    'I need an inspection',
    'Check engine light',
    'Vehicle issue diagnostic',
    'I need an oil change',
    // inspection specific
    'Pre purchase',
    'Road trip',
    '80 point full mechanical inspection'
]

// Contact
export const contactCommMethodData = [
    'Call',
    "Text",
    'Email',
    'Carrier Pigeon',
]

// Vehhicle Info
export const vehicleOwnedInfo = [
    'Less than six months (may we suggest scheduling a 80 point evaluation?)',
    "Six months to one year",
    'One to five years',
    'Over five years',
    "I've owned this vehicle since new"
]

// Budget
export const windshieldWipersData = [
    'working great',
    "meh",
    'oh, yeah. Maybe',
    'I am a DIY queen',
    'Other',
]
export const extraRepairData = [
    'Please contact me regarding ANY additional minor repairs that need attention before performing repairs. Due to the lag in communication, I understand I may need to schedule a second appointment to address these issues.',
    'Please perform additional minor but necessary repairs, under $40',
    'Other',
]


// Pickup
export const pickupData = [
    'I plan on waiting while my vehicle is serviced (eg oil change)',
    "End of Day",
    '9am',
    '11am',
    '2:30pm',
    '4:30-5:30pm',
    'After 5:30pm',
    "I'm Flexible",
    'Other',
]








