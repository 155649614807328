import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

function WorkedWithThisVehicle(props) {
    
    const [pageState, setPageState] = useState(
        props.formData.workedWithVehicle
    );

    useEffect(() => {
        if(pageState === false || pageState === undefined) {
            window.sessionStorage.setItem("next_page", 'VehicleInfo')
        } else {
            window.sessionStorage.setItem("next_page", 'Disclaimers')
        }
        document.getElementById('nextButton').disabled = (pageState === undefined)
    });
    
    return (
        <div>
        <Card>
        <Card.Body>
        <Form.Group controlId="WorkedWithThisVehicle">
            <Form.Label><b>Have we worked on this particular vehicle before?</b><div class="required_text">*required</div></Form.Label>
            <Form.Check 
                type="radio"
                id="workedWithVehicleNo"
                label="No, this vehicle is new to Dean's"
                checked={props.formData.workedWithVehicle === false && props.formData.workedWithVehicle !== undefined}
                onClick={function(e) {checkBoxOnChange(props.formData, e); setPageState(false)} }
                
            />
            <Form.Check 
                type="radio"
                id="workedWithVehicleYes"
                label="Yes, Dean's team has worked on this vehicle"
                checked={props.formData.workedWithVehicle === true}
                onClick={function(e) {checkBoxOnChange(props.formData, e); setPageState(true)} }
            />
        </Form.Group>
        </Card.Body>
        </Card>
        </div>
    )
}


function checkBoxOnChange(formData, e) {
    // e.preventDefault();
    if(e.target.id === 'workedWithVehicleNo') {
        formData.workedWithVehicle = false
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
          );
    }

    if(e.target.id === 'workedWithVehicleYes') {            
        formData.workedWithVehicle = true
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
          );
    }
}


export default WorkedWithThisVehicle;












