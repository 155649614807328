
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';


function NameVehicle(props) {

    // const pageId = "NameVehicle"
    const nextPageId = "WorkedWithYou"
    
    useEffect(() => {
        // console.log("ise effect "+ props.formData.customerName)
        window.sessionStorage.setItem("next_page", nextPageId)
        document.getElementById('nextButton').disabled = (props.formData.customerName === undefined
                                                          || props.formData.customerName === '')
    });

    return (
        <div>

        <Card>
            <Card.Body>
                
            <Form.Group controlId="formBasicName">
                <Form.Label><div>Full Name</div><div class="required_text">*required</div></Form.Label>
                <Form.Control type="text" placeholder="First and Last" defaultValue={props.formData.customerName} onChange={(e) => handleFullName(props.formData, e)}/>
            </Form.Group>

            <Form.Group controlId="vehicleInfo">
                <Form.Label>What is the year (if known), make (manufacturer) and model of your vehicle</Form.Label>
                <Form.Control type="text" placeholder="Year Make Model" defaultValue={props.formData.vehicleInfo} onChange={(e) => handleVehicleInfo(props.formData, e)} />
            </Form.Group>
                
            </Card.Body>
        </Card>

        
        </div>
    );
}

function handleFullName(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.customerName = value
    document.getElementById('nextButton').disabled = (value === '')
    // this.setState({update: 'g'})   
}

function handleVehicleInfo(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.vehicleInfo = value
    // this.setState({update: 'g'})
}


export default NameVehicle;
