
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import * as consts from '../choices/formChoices';

function VehicleInfo(props) {

    const nextPageId = "Disclaimers"
    const [vehicleOwnedState, setVehicleOwnedState] = useState(
        props.formData.vehicleOwned
    );
    
    useEffect(() => {
        window.sessionStorage.setItem("next_page", nextPageId)

        document.getElementById('nextButton').disabled = (props.formData.vehiclePlateInfo === undefined ||
                                                         props.formData.vehiclePlateInfo === '')
    });

    return (
        <div>
        <h2><Badge variant="primary">License Plate</Badge></h2>

        <Card>
        <Card.Body>
        <Form.Group controlId="vehicleInfoLic">
            <Form.Label>What is the vehicles license plate number and state (Yes, it's a pain- but we absolutely need this info if we haven't worked on the vehicle before-it ensures we purchase the correct parts and expedites your visit- thanks!)
            <div class="required_text">*required</div>
            </Form.Label>
            <Form.Control type="text" placeholder="Your answer" defaultValue={props.formData.vehiclePlateInfo} onChange={(e) => handleVehicleInfo(props.formData, e)}/>
        </Form.Group>
        </Card.Body>
        </Card>

        <p></p>

        <Card>
        <Card.Body>
        <Form.Group controlId="vehicleOwned">
            <Form.Label>Approximately how long have you owned this vehicle?</Form.Label>
            <Form.Check 
                id="v1"
                type="radio"
                label={consts.vehicleOwnedInfo[0]}
                checked={props.formData.vehicleOwned === 0}
                onClick={function(e) {onCheckBoxChange(props.formData, e, 0); setVehicleOwnedState(0)} }
                
            />
            <Form.Check
                id="v2" 
                type="radio"
                label={consts.vehicleOwnedInfo[1]}
                checked={props.formData.vehicleOwned === 1}
                onClick={function(e) {onCheckBoxChange(props.formData, e, 1); setVehicleOwnedState(1)} }
                
            />
            <Form.Check 
                id="v3"
                type="radio"
                label={consts.vehicleOwnedInfo[2]}
                checked={props.formData.vehicleOwned === 2}
                onClick={function(e) {onCheckBoxChange(props.formData, e, 2); setVehicleOwnedState(2)} }
                
            />
            <Form.Check 
                id="v4"
                type="radio"
                label={consts.vehicleOwnedInfo[3]}
                checked={props.formData.vehicleOwned === 3}
                onClick={function(e) {onCheckBoxChange(props.formData, e, 3); setVehicleOwnedState(3)} }
            />
            
            <Form.Check 
                id="v5"
                type="radio"
                label={consts.vehicleOwnedInfo[4]}
                checked={props.formData.vehicleOwned === 4}
                onClick={function(e) {onCheckBoxChange(props.formData, e, 4); setVehicleOwnedState(4)} }
                
            />
        </Form.Group>
        </Card.Body>
        </Card>

        <p></p>
        <Card>
        <Card.Body>
        <Form.Group controlId="vehicleInfoColor">
            <Form.Label>Vehicle Color
            </Form.Label>
            <Form.Control type="text" placeholder="Your answer" defaultValue={props.formData.vehicleColor} onChange={(e) => handleVehicleColor(props.formData, e)}/>
        </Form.Group>
        </Card.Body>
        </Card>
        
        </div>
    );
}

function handleVehicleInfo(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.vehiclePlateInfo = value
    document.getElementById('nextButton').disabled = (value === undefined || value === '')
    // this.setState({update: 'g'})   
}

function handleVehicleColor(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.vehicleColor = value
    // this.setState({update: 'g'})   
}

function onCheckBoxChange(formData, e, vehicleOwnedState) {
    // e.preventDefault();
    formData.vehicleOwned = vehicleOwnedState    
    window.sessionStorage.setItem(
        "form_data",
        JSON.stringify(formData)
        );

}

export default VehicleInfo;
