
import React, { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';

function prettyApptDate(date) {
    // var ps =  date.toDateString()
    var ps =  date.toLocaleString()
    ps = ps.replace(/:\d+ (\w\w)$/, '$1');
    ps = ps.replace(", ", ' @ ');
    return ps
}

function Finished(props) {
    return (
        <div style={{textAlign:"center"}}>
            {props.failed === true &&
                <h1><Badge variant="primary">We're having technical difficulties, please try again in a few minutes. Our apologies.</Badge></h1>        
            }
            {props.failed === false &&
                <h2><Badge variant="primary">We received your request for {prettyApptDate(props.apptDate)} we will be in touch soon to confirm the date and ask any follow up questions.</Badge></h2>
                
            }
        
        </div>
    );
}



export default Finished;
