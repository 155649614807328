import { Checkbox } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import * as consts from '../choices/formChoices';

function Contact(props) {

    const nextPageId = "WorkedWithThisVehicle"
    
    const [textAllowedState, setTextAllowedState] = useState(
        props.formData.textAllowed
    );

    const [commMethodState, setCommMethodState] = useState(
        props.formData.commMethod
    );


    let updateCommMethodState = (formData, val) => {
        formData.commMethod = val
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );

        setCommMethodState(val)
    }
    
    useEffect(() => {
        window.sessionStorage.setItem("next_page", nextPageId)
        document.getElementById('nextButton').disabled = (props.formData.phone === undefined ||
                                                          props.formData.phone === '' || props.formData.phone.length < 6)
    });

    return (
        <div>

        {/* <Card>
            <Card.Body>
                
            </Card.Body>
        </Card> */}
        
        <h2><Badge variant="primary">Contact</Badge></h2>
        <Card>
            <Card.Body>
            <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter Email" defaultValue={props.formData.email} onChange={(e) => handleEmail(props.formData, e)}/>
            </Form.Group>

            <Form.Group controlId="phone">
                <Form.Label>Phone number<div class="required_text">*required</div></Form.Label>
                <Form.Control type="tel" placeholder="Enter Phone Number" defaultValue={props.formData.phone} onChange={(e) => handlePhone(props.formData, e)} />
            </Form.Group>
            </Card.Body>
        </Card>
        
        <p></p>

        <Card>
        <Card.Body>
        <Form.Group controlId="textAllowed">
            <Form.Label>Can we text you regarding your vehicle at this number?</Form.Label>
            <Form.Check 
                type="radio"
                id="textNo"
                label="no"
                checked={props.formData.textAllowed === 0}
                onClick={function(e) {checkBoxOnChange(props.formData, e, 0); setTextAllowedState(0)} }
                
            />
            <Form.Check 
                type="radio"
                id="textYes"
                label="yes"
                checked={props.formData.textAllowed === 1}
                onClick={function(e) {checkBoxOnChange(props.formData, e, 1); setTextAllowedState(1)} }
                
            />
        </Form.Group>
        </Card.Body>
        </Card>
        
        <p></p>
        
        <Card>
        <Card.Body>
        <Form.Group controlId="preferredCommMethod">
            <Form.Label>Please indicate your preferred method of communication</Form.Label>
            <Form.Check 
                id="pcm1"
                type="radio"
                label={consts.contactCommMethodData[0]}
                checked={props.formData.commMethod === 0}
                // onClick={function(e) {commMethodOnChange(props.formData, e, 0); setCommMethodState(0)} }
                onClick={function(e) {updateCommMethodState(props.formData, 0)} }
            />
            <Form.Check
                id="pcm2"
                type="radio"
                label={consts.contactCommMethodData[1]}
                checked={props.formData.commMethod === 1}
                // onClick={function(e) {commMethodOnChange(props.formData, e, 1); setCommMethodState(1)} }
                onClick={function(e) {updateCommMethodState(props.formData, 1)} }
                
            />
            <Form.Check 
                id="pcm3"
                type="radio"
                label={consts.contactCommMethodData[2]}
                checked={props.formData.commMethod === 2}
                // onClick={function(e) {commMethodOnChange(props.formData, e, 2); setCommMethodState(2)} }
                onClick={function(e) {updateCommMethodState(props.formData, 2)} }
                
            />
            <Form.Check 
                id="pcm4"
                type="radio"
                label={consts.contactCommMethodData[3]}
                checked={props.formData.commMethod === 3}
                // onClick={function(e) {commMethodOnChange(props.formData, e, 3); setCommMethodState(3)} }
                onClick={function(e) {updateCommMethodState(props.formData, 3)} }
                
            />            
        </Form.Group>
        </Card.Body>
        </Card>

        {/* <div class="form-check">
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input" value="">Option 1</input>
        </label>
        </div>
        <div class="form-check">
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input" value="">Option 2</input>
        </label>
        </div>
        <div class="form-check">
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input" value="" disabled>Option 3</input>
        </label>
        </div> */}

        </div>
    );
}

function handleEmail(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.email = value

    // this.setState({update: 'g'})   
}

function handlePhone(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.phone = value
    // this.setState({update: 'g'})
    document.getElementById('nextButton').disabled = (value === undefined ||
                                                      value === '' || value.length < 10)
}

function checkBoxOnChange(formData, e, textAllowedState) {
    // e.preventDefault();
    formData.textAllowed = textAllowedState    
    window.sessionStorage.setItem(
        "form_data",
        JSON.stringify(formData)
        );
}

function commMethodOnChange(formData, e, commMethodState) {
    // e.preventDefault();
    formData.commMethod = commMethodState    
    window.sessionStorage.setItem(
        "form_data",
        JSON.stringify(formData)
        );

}

export default Contact;
