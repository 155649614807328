import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

function WorkedWithYou(props) {
    
    const [pageState, setPageState] = useState(
        props.formData.workedWithYou
    );

    useEffect(() => {
        if(pageState === false) {
            window.sessionStorage.setItem("next_page", 'Contact')
        } else {
            window.sessionStorage.setItem("next_page", 'ContactPhoneOnly')
        }

        document.getElementById('nextButton').disabled = (pageState === undefined)
    });

    
    return (
        <div>
        <p></p>
        <Card>
        <Card.Body>
        <Form.Group controlId="workedWithYou">
            <Form.Label><h5>Have we previously worked with you?</h5><div class="required_text">*required</div></Form.Label>
            <Form.Check 
                type="radio"
                id="workedWithYouNo"
                label="No, I need to give you contact information"
                checked={props.formData.workedWithYou === false && props.formData.workedWithYou !== undefined}
                onClick={function(e) {checkBoxOnChange(props.formData, e); setPageState(false)} }
                
            />
            <Form.Check 
                type="radio"
                id="workedWithYouYes"
                label="Yes, skip the contact collection section"
                checked={props.formData.workedWithYou === true}
                onClick={function(e) {checkBoxOnChange(props.formData, e); setPageState(true)} }
            />
        </Form.Group>
        </Card.Body>
        </Card>
        </div>
    )
}


function checkBoxOnChange(formData, e) {
    // e.preventDefault();
    if(e.target.id === 'workedWithYouNo') {
        formData.workedWithYou = false
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
          );
    }

    if(e.target.id === 'workedWithYouYes') {            
        formData.workedWithYou = true
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
          );
    }
}


export default WorkedWithYou;












