import axios from "axios";

import React, { Component, useEffect } from "react";

// DatePicker
import DatePicker from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'; # provide my own?
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

// Bostrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';


import NameVehicle from '../components/NameVehicle';
import WorkedWithYou from '../components/WorkedWithYou';
import BookDateTime from '../components/BookDateTime';
import WorkedWithThisVehicle from '../components/WorkedWithThisVehicle';
import Purpose from '../components/Purpose';
import Disclaimers from '../components/Disclaimers';
import Contact from '../components/Contact';
import ContactPhoneOnly from '../components/ContactPhoneOnly';
import VehicleInfo from '../components/VehicleInfo';
// import Budget from '../components/Budget';
// import GenericIssue from '../components/GenericIssue';
// import FunkyNotes from '../components/FunkyNotes';
// import Cost from '../components/Cost';
import Finished from '../components/Finished';

import * as consts from '../choices/formChoices';

// import 'bootstrap/dist/css/bootstrap.min.css'; 
import "react-datepicker/dist/react-datepicker.css";


class FormContainer extends Component {
        
    constructor(props) {
        super(props);

        this.formData = {
            update: '',
            customerName: undefined,
            vehicleInfo: undefined,
            workedWithYou: undefined,
            workedWithVehicle: undefined,
            purposeOptions:Array(5).fill(false),
            email:undefined,
            phone:undefined,
            textAllowed:undefined,
            commMethod:undefined,
            vehiclePlateInfo:undefined,
            secondaryIssue:undefined,
            vehicleOwned:undefined,
            extraRepairState:Array(3).fill(false),
            windshieldWipersState:undefined,
            repairBudget:undefined,
            complaintDetails:undefined,
            genericIssues:Array(10).fill(false),
            auxIssues:Array(7).fill(false),
            newIssueSoundsLikeId:-1,
            funkyNotes: Array(6).fill(false),
            costExpectId:undefined,
            agreeToFeeStructure:false,
            jdmVehicle:null,
            agreeToDisclaimer:false,
            
            complainOtherIssue:undefined,
            complainSecondaryOther:undefined,
            wipersOther:undefined,
            complainSoundsLikeOther:undefined,
            funkyNotesOther:undefined,
            budgetOther:undefined,
            costOther:undefined,
            pickupTimes: Array(9).fill(false),
            pickupTimeOther:undefined,
            vehicleColor:undefined,
            
        }

        this.state = { 
            apptDate: undefined,
            startSpinner: false,
            submitted: false,
            errorSubmitting: false,
            blockedDatesLoaded:false,
            maxAllowedDatesLoaded:false,
            availableDatesLoaded:false,
        };

        this.curPage = "BookDateTime"
        // this.curPage = "Purpose"
       
        this.fetchToken = this.fetchToken.bind(this)

        this.refreshAvailableDates = this.refreshAvailableDates.bind(this)
        this.processAvailableDates = this.processAvailableDates.bind(this)

        this.refreshBlockedDates = this.refreshBlockedDates.bind(this)
        this.processBlockedDates = this.processBlockedDates.bind(this)
        
        this.refreshMaxAllowedDates = this.refreshMaxAllowedDates.bind(this)
        this.processMaxAllowedDates = this.processMaxAllowedDates.bind(this)
        
        this.handleDateChange = this.handleDateChange.bind(this)

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);

        this.inputComponents = {
            'BookDateTime': <BookDateTime formData={this.formData} onChange={this.handleDateChange} />,
            'NameVehicle': <NameVehicle formData={this.formData} />,
            'WorkedWithYou': <WorkedWithYou formData={this.formData} />,
            'Contact': <Contact formData={this.formData} />,
            'ContactPhoneOnly': <ContactPhoneOnly formData={this.formData} />,
            'WorkedWithThisVehicle': <WorkedWithThisVehicle formData={this.formData} />,
            'VehicleInfo': <VehicleInfo formData={this.formData} />,
            'Disclaimers': <Disclaimers formData={this.formData} />,
            'Purpose': <Purpose formData={this.formData} />,
            // 'Budget': <Budget formData={this.formData} />,
            // 'GenericIssue': <GenericIssue formData={this.formData} />,
            // 'FunkyNotes': <FunkyNotes formData={this.formData} />,
            // 'Cost': <Cost formData={this.formData} />,
            // 'BookDateTime': <BookDateTime formData={this.formData} onChange={this.handleDateChange} />
        };
    }

    componentDidMount() {
        this.fetchToken();
        this.interval = setInterval(() => {this.fetchToken()}, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate() {
        // console.log("form data: " + JSON.stringify(this.formData))
        window.sessionStorage.setItem(
            "form_data", JSON.stringify(this.formData)
          );
        
          window.sessionStorage.setItem(
            "cur_page", this.curPage
          );

      }

      processAvailableDates(response) {
        var avaialbeDatesStr = {dateStrs: []}

        response.data.apt_booking_dates.forEach(function (value) {
            // blockedDates.push(Date.parse(value))
            avaialbeDatesStr.dateStrs.push(value)
        });

        
        window.sessionStorage.setItem(
            "available_dates",
            JSON.stringify(avaialbeDatesStr)
        );

        this.setState({
            gg: 'gg',
            availableDatesLoaded:true
        });
    }

    processBlockedDates(response) {
        var blockedDatesStrs = {dateStrs: []}
        
        response.data.blocked_dates.forEach(function (value) {
            // blockedDates.push(Date.parse(value))
            blockedDatesStrs.dateStrs.push(value)
        });

        window.sessionStorage.setItem(
            "blocked_dates",
            JSON.stringify(blockedDatesStrs)
        );
        
        this.setState({
            gg: 'gb',
            blockedDatesLoaded: true

        });
    }

    processMaxAllowedDates(response) {
        var dates = {dateStrs: []}
        response.data.max_allowed.forEach(function (value) {
            // blockedDates.push(Date.parse(value))
            dates.dateStrs.push(value)
        });

        window.sessionStorage.setItem(
            "max_allowed_dates",
            JSON.stringify(dates)
        );
        
        this.setState({
            gg: 'ghb',
            maxAllowedDatesLoaded:true
        });
    }
    
    fullLogIn = () => {
        var bodyFormData = new FormData();
        bodyFormData.set('username', 'oleg');
        bodyFormData.append('password', 'harvey123');

        axios
            .post("/api/authentication/token/", bodyFormData)
            .then(res => {
                // console.log(res)
              
                window.sessionStorage.setItem(
                    "access_token", res.data.access
                );
                
                window.sessionStorage.setItem(
                    "refresh_token", res.data.refresh
                );

                this.refreshAvailableDates();
                this.refreshBlockedDates();
                this.refreshMaxAllowedDates();
            })
            .catch(err => console.log(err));
    }

    fetchToken = () => {
        let refresh_token = window.sessionStorage.getItem("refresh_token");

        if(refresh_token !== undefined) {
            var bodyFormData = new FormData();
            bodyFormData.set('refresh', refresh_token);
            axios
                .post("/api/authentication/token/refresh/", bodyFormData)
                .then(res => {
                    console.log(res)
                
                    window.sessionStorage.setItem(
                        "access_token", res.data.access
                    );

                    this.refreshAvailableDates();
                    this.refreshBlockedDates();
                    this.refreshMaxAllowedDates();

                })
                .catch(err => {
                    this.fullLogIn();
                    console.log(err);
                });
        } else {
            this.fullLogIn()
        }

        
    }

    refreshBlockedDates = () => {
        let access_token = window.sessionStorage.getItem('access_token')
        const config = {
            headers: { Authorization: `Bearer ${access_token}` }
        };

        axios
            .get("/api/gcal/blocked_dates/", config)
            .then(res => this.processBlockedDates(res))
            .catch(err => console.log(err));
    };

    refreshAvailableDates = () => {
        let access_token = window.sessionStorage.getItem('access_token')
        const config = {
            headers: { Authorization: `Bearer ${access_token}` }
        };
        
        axios
            .get("/api/gcal/available_slots/", config)
            .then(res => this.processAvailableDates(res))
            .catch(err => console.log(err));
    };

    refreshMaxAllowedDates = () => {
        let access_token = window.sessionStorage.getItem('access_token')
        const config = {
            headers: { Authorization: `Bearer ${access_token}` }
        };

        axios
            .get("/api/gcal/max_appt_per_day/", config)
            .then(res => this.processMaxAllowedDates(res))
            .catch(err => console.log(err));
    };
    

    /* This lifecycle hook gets executed when the component mounts */

    handleDateChange(date) {
        // console.log('date chage ' + date)

        window.sessionStorage.setItem(
            "form_data", JSON.stringify(this.formData)
        );
        
        this.setState({
            apptDate: date
        });
    };

    handleFormSubmit(e) {
        console.log("SUB")
        e.preventDefault();
        var bodyFormData = new FormData();
        var form_data = JSON.parse(window.sessionStorage.getItem('form_data')) || {}
        bodyFormData.set('customer_name', form_data.customerName);
        bodyFormData.append('appt_date', this.state.apptDate.toISOString());
        bodyFormData.append('vehicle_info', form_data.vehicleInfo);
        bodyFormData.append('vehicle_plate_info', form_data.vehiclePlateInfo);
        bodyFormData.append('vehicle_color', form_data.vehicleColor);
        bodyFormData.append('worked_with_you', form_data.workedWithYou);
        bodyFormData.append('worked_with_vehicle', form_data.workedWithVehicle);
        // if(form_data.purposeId >= 0 && purposeData < consts.purposeData.length) {
        // }
        // bodyFormData.append('purpose', consts.purposeData[form_data.purposeId] || '');
        bodyFormData.append('email', form_data.email);
        bodyFormData.append('phone', form_data.phone);
        bodyFormData.append('text_allowed', (form_data.textAllowed === 1) ? 'TRUE' : 'FALSE');
        bodyFormData.append('comm_method', consts.contactCommMethodData[form_data.commMethod] || '')
        bodyFormData.append('vehicle_owned_info', consts.vehicleOwnedInfo[form_data.vehicleOwned] || '')

        let repairIssues = []
        form_data.extraRepairState.forEach(function (item, index){
            if(item === true) {
                repairIssues.push(consts.extraRepairData[index])
            }
        })
        bodyFormData.append('extra_repairs', repairIssues)

        bodyFormData.append('windshield_wipers', consts.windshieldWipersData[form_data.windshieldWipersState] || '')
        bodyFormData.append('repair_budget', form_data.repairBudget);
        bodyFormData.append('complaint_details', form_data.complaintDetails);

        let genericIssues = []
        form_data.genericIssues.forEach(function (item, index){
            if(item === true) {
                // console.log("pushing: " + consts.issueData[index])
                genericIssues.push(consts.issueData[index])
            }
        })
        bodyFormData.append('generic_issues', genericIssues);

        let auxIssues = []
        form_data.auxIssues.forEach(function (item, index){
            if(item === true) {
                auxIssues.push(consts.auxCluesData[index])
            }
        })
        bodyFormData.append('aux_issues', auxIssues);
        
        let purposeOptions = []
        form_data.purposeOptions.forEach(function (item, index){
            if(item === true) {
                purposeOptions.push(consts.purposeData[index])
            }
        })
        bodyFormData.append('purpose', purposeOptions);

        bodyFormData.append('issue_sounds_like', consts.issueSoundsLikeData[form_data.newIssueSoundsLikeId] || 'n/a')

        let funkyNotes = []
        form_data.funkyNotes.forEach(function (item, index){
            if(item === true) {
                funkyNotes.push(consts.funkyNotesData[index])
            }
        })
        bodyFormData.append('funky_notes', funkyNotes);

        bodyFormData.append('cost_expected', consts.costExpectData[form_data.costExpectId] || '')
        bodyFormData.append('agree_to_fee', form_data.agreeToFeeStructure);
        bodyFormData.append('jdm_vehicle', form_data.jdmVehicle);
        bodyFormData.append('secondary_issue', form_data.secondaryIssue);

        bodyFormData.append('complaint_otherissue', form_data.complainOtherIssue);
        bodyFormData.append('complaint_secondother', form_data.complainSecondaryOther);
        bodyFormData.append('wipers_other', form_data.wipersOther);
        bodyFormData.append('complaint_soundslike_other', form_data.complainSoundsLikeOther);
        bodyFormData.append('funky_other', form_data.funkyNotesOther);
        bodyFormData.append('budget_other', form_data.budgetOther);
        bodyFormData.append('cost_other', form_data.costOther);
        bodyFormData.append('pickup_other', form_data.pickupTimeOther);
        
        let pickupChoices = []
        form_data.pickupTimes.forEach(function (item, index){
            if(item === true) {
                pickupChoices.push(consts.pickupData[index])
            }
        })
        bodyFormData.append('pickup_times', pickupChoices);
        
        
        this.setState({
            startSpinner: true,
        })

        console.log('SUZBMIT: ' + bodyFormData)
        let access_token = window.sessionStorage.getItem('access_token')
        const config = {
            headers: { Authorization: `Bearer ${access_token}` }
        };

        axios
            .post("/api/gcal/submit_appt/", bodyFormData, config)
            .then(res => {
                this.refreshBlockedDates()
                this.setState({
                    submitted: true,
                    startSpinner: false,
                    errorSubmitting: false
                });
            })
            .catch(err => {
                this.refreshBlockedDates()
                this.setState({
                        submitted: true,
                        startSpinner: false,
                        errorSubmitting: true
                });
            })
    }

    handleBack(e) {
        e.preventDefault();
        
        var pageHistoryStack = JSON.parse(window.sessionStorage.getItem("page_history_stack")) || ['BookDateTime']
        let prevPageId = pageHistoryStack.pop()
        window.sessionStorage.setItem("page_history_stack", JSON.stringify(pageHistoryStack))
        

        window.sessionStorage.setItem("cur_page", prevPageId)        
        this.curPage = prevPageId
        this.setState({startSpinner: false, submitted: false})
        this.forceUpdate()
    }
    
    handleNext(e) {
        e.preventDefault();
        
        var pageHistoryStack = JSON.parse(window.sessionStorage.getItem("page_history_stack")) || []
        pageHistoryStack.push(this.curPage)
        window.sessionStorage.setItem("page_history_stack", JSON.stringify(pageHistoryStack))

        let nextPageId = window.sessionStorage.getItem("next_page") || 'NameVehicle'
        window.sessionStorage.setItem("cur_page", nextPageId)
        
        this.curPage = nextPageId
        this.setState({startSpinner: false, submitted: false})
        this.forceUpdate()
    }

    
    render() {
        
        return ( 
            <Container>
                <Row>
                    <Col>
                        <h1><Badge variant="secondary">Dean’s Car Care Booking form</Badge></h1>
                            
                        {/* <div style={{backgroundImage : 'left', paddingRight : '5px'}}></div> */}
                        <div style={{backgroundImage: "url(./images/header.jpg)", backgroundSize: "cover", 
                        backgroundRepeat: "no-repeat", height:'10em'}}></div>

                    </Col>
                </Row>
                <br></br>
                {this.state.submitted === true &&
                    <Finished failed={this.state.errorSubmitting} apptDate={this.state.apptDate}/>
                }

                {this.curPage === 'NameVehicle' && this.state.submitted !== true &&
                    <Row>
                        <Col><h6>Thank you for completing a online intake form!  Thoroughly completing this intake will help us get you the most bang-for-your buck and facilitate clear communication.</h6>
                        <p></p>
                        </Col>
                    </Row>
                }
                {this.state.submitted !== true && 
                    <Row>
                        <Col>
                            <Form>
                                {
                                    Object.entries(this.inputComponents).map( ([key, value]) => { 
                                        if (key === this.curPage && this.state.blockedDatesLoaded === true && this.state.maxAllowedDatesLoaded === true && this.state.availableDatesLoaded === true)
                                            {return value} else return null} )
                                }
                                
                                <p/>
                                
                                {this.curPage !== 'BookDateTime' &&
                                    <Button disabled={this.state.startSpinner === true}
                                        onClick={this.handleBack}
                                        type={"secondary"}
                                    >Back</Button>
                                }

                                {" "}{this.curPage !== 'Purpose' && this.state.blockedDatesLoaded === true && this.state.maxAllowedDatesLoaded === true && this.state.availableDatesLoaded === true &&
                                    <Button
                                        id="nextButton"
                                        onClick={this.handleNext}
                                        type={"primary"}
                                    >Next</Button>
                                }{" "}

                                {this.curPage === 'Purpose' &&
                                    // <Button disabled={this.state.startSpinner === true || this.state.apptDate === undefined || this.formData.pickupTimeOther === undefined} 
                                    <Button disabled={this.state.startSpinner === true || this.state.apptDate === undefined}
                                        onClick={this.handleFormSubmit}
                                        type={"primary"}
                                        animation="border"
                                        id="submitButton">
                                        {this.state.startSpinner === true &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    Submit</Button>
                                }

                                {this.curPage === 'BookDateTime' &&
                                    (this.state.blockedDatesLoaded === false || this.state.maxAllowedDatesLoaded === false || this.state.availableDatesLoaded === false) &&
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </div>
                                }
                                
                                {/* {this.state.startSpinner === true && 
                                    <Spinner animation="border" variant="primary" />
                                } */}

                            </Form>
                        </Col>
                    </Row>
                }

                <p>
                <br></br>
                <div style={{textAlign:"center"}}>
                <img src="./images/dcc_logo.png" style={{height:'4em'}}></img>
                </div>
                </p>
            </Container>
            
        );
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
};

export default FormContainer;
