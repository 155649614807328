import React, { Component, useState } from "react";
import logo from './logo.svg';

import FormContainer from "./containers/FormContainer";
// import DateTimeContainer from "./containers/DateTimeContainer"


import './App.css';


class App extends Component {
  state = {
    startDate: new Date()
  };

  render() {
    
    return <FormContainer></FormContainer>
  }

  
}
export default App;


