
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';

import * as consts from '../choices/formChoices';

function Disclaimers(props) {

    const nextPageId = "Purpose"

    const [jdmVehicleState, setjdmVehicleState] = useState(
        props.formData.jdmVehicle
    );

    const [agreeToDisclaimerState, setagreeToDisclaimerState] = useState(
        props.formData.agreeToDisclaimer
    );

    let updatejdmVehicleState = (val, formData) => {
        formData.jdmVehicle = val 
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );

        setjdmVehicleState(!jdmVehicleState)        
    }

    let updateagreeToDisclaimerState = (formData) => {
        formData.agreeToDisclaimer = !agreeToDisclaimerState 
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );

        setagreeToDisclaimerState(!agreeToDisclaimerState)        
    }

    useEffect(() => {
        window.sessionStorage.setItem("next_page", nextPageId)

        document.getElementById('nextButton').disabled = (props.formData.agreeToDisclaimer === false)
    });

    return (
        <div>
        <h2><Badge variant="primary">Disclaimer</Badge></h2>
        
        <p></p>
        
        {/* <Card>
        <Card.Body>
        <Form.Group controlId="complaintDetails">
            <Form.Label>Include details like the engine cranks-but doesn't start or, the heater blows-but the air never gets hot. Smells, color of smoke, etc.
                        <div class="required_text">*required</div>
            </Form.Label>
            <Form.Control type="text" placeholder="Your answer" defaultValue={props.formData.complaintDetails} onChange={(e) => handleComplaint(props.formData, e)}/>
        </Form.Group>
        </Card.Body>
        </Card>

        <p></p> */}

        <Card>
        <Card.Body>
        <Form.Group controlId="jdmState">
            <Form.Label>Is this vehicle 1996 or NEWER (Excluding right-hand drive (JDM) vehicles, and specialty modified vehicles)?</Form.Label>
            <Form.Check 
                id="JDMNO"
                type="radio"
                label="Yes"
                checked={props.formData.jdmVehicle === false}
                onClick={function(e) {updatejdmVehicleState(false, props.formData)} }
                
            />
            <Form.Check 
                id="JDMYES"
                type="radio"
                label="No"
                checked={props.formData.jdmVehicle === true}
                onClick={function(e) {updatejdmVehicleState(true, props.formData)} }
                
            />
                
        </Form.Group>
        </Card.Body>
        </Card>
        
        {props.formData.jdmVehicle === true && 
        <p></p>&&
        <Card>
        <Card.Body>
        <Form.Group controlId="jdmlDisclaimer">
            <div>Specialty rates are applied to vehicles that are OBD I (1995 and older), right-hand drive (JDM), and any other vehicle that has more one-off or custom platforms, modifications, and or any description that would indicate these vehicles are not by design or when it was originally assembled for use. Specialty rates are applied in order to account for the circumstances that go into performing diagnostics and repairs for specialty vehicles; where added procedures, times, research, and rebuilds are necessary to keep the vehicle alive and well. Oftentimes, these vehicles have had a certain level of undocumented modifications to navigate around and can be notorious for deep, multi-level, multi-repair diagnostics that can take more than one visit to rectify concerns. On the parts level: multiple avenues are cutting designs on older vehicles which reduces the availability and integrity of replacement parts.</div>
            <br></br>
            <div>* Quality and OEM parts are often not available, difficult to get to the US,  and sometimes not available at all. Cheap parts are sometimes all we can get, and as such all parts installed do not come with a warranty.</div>
            <br></br>
            <div>* No repair guides or english manuals for most of the imports we see (as well as custom or modified works) , and as such all repairs are as-is and do not have any implied warranties.</div>
            <br></br>
            <div>* Waiting appointments and time constrictive appointments WILL NOT be scheduled for these vehicles as parts and repairs often take 2-16 weeks, expect major wait times for even the more simple repairs.</div>
            <br></br>
            <div>Do not let the strong wording dissuade you, we love, and specialize in these vehicles, and we can not survive as a shop if we have to stand behind a lack of diagnostic information and poor parts. Just be ready for this as part of the ownership experience of driving an exotic vehicle.</div>
            <br></br>
            <div>Specialty vehicles include RV's, vans, campers, K-cars, modified vehicles and more. The initial diagnosis starts at $170 for one issue and does not include the cost of parts. In limited cases, this may be reduced for simpler concerns/resolutions - we will contact you before going over this fee unless previously discussed or explicitly indicated in your budget. All vehicles receive a free courtesy inspection that includes a review of most fluids, drive belt(s), and battery inspection, because we like you.</div>


            <Form.Label><div class="required_text">*required</div></Form.Label>
            <Form.Check
                class="primary"
                type="checkbox"
                label="I understand"
                checked={agreeToDisclaimerState === true}
                onClick={function(e) {updateagreeToDisclaimerState(props.formData)} }
                
            />
        </Form.Group>
        </Card.Body>
        </Card>
        }

        {props.formData.jdmVehicle === false && 
        <p></p>&&
        <Card>
        <Card.Body>
        <Form.Group controlId="standardDisclaimer">
        <div>Vehicles 1996 and newer (excludes right hand drive, and all specialty vehicles). The initial diagnosis starts at $150 for one issue and does not include the cost of parts. In limited cases, this may be reduced for simpler concerns/resolutions - we will contact you before going over this fee unless previously discussed or explicitly indicated in your budget. All vehicles receive a free courtesy inspection that includes a review of most fluids, drive belt(s), and battery inspection, because we like you.</div>
            
            <Form.Label><div class="required_text">*required</div></Form.Label>
            <Form.Check
                class="primary"
                type="checkbox"
                label="I understand"
                checked={agreeToDisclaimerState === true}
                onClick={function(e) {updateagreeToDisclaimerState(props.formData)} }
                
            />
        </Form.Group>
        </Card.Body>
        </Card>
        }

        </div>
    );
}


export default Disclaimers;

