import { Checkbox } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import * as consts from '../choices/formChoices';

function ContactPhoneOnly(props) {

    const nextPageId = "WorkedWithThisVehicle"
    
    const [textAllowedState, setTextAllowedState] = useState(
        props.formData.textAllowed
    );

    const [commMethodState, setCommMethodState] = useState(
        props.formData.commMethod
    );


    let updateCommMethodState = (formData, val) => {
        formData.commMethod = val
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );

        setCommMethodState(val)
    }
    
    useEffect(() => {
        window.sessionStorage.setItem("next_page", nextPageId)
        document.getElementById('nextButton').disabled = (props.formData.phone === undefined ||
                                                          props.formData.phone === '' || props.formData.phone.length < 6)
    });

    return (
        <div>

        {/* <Card>
            <Card.Body>
                
            </Card.Body>
        </Card> */}
        
        <h2><Badge variant="primary">Contact</Badge></h2>
        <Card>
            <Card.Body>
            <Form.Group controlId="phone">
                <Form.Label>Phone number<div class="required_text">*required</div></Form.Label>
                <Form.Control type="tel" placeholder="Enter Phone Number" defaultValue={props.formData.phone} onChange={(e) => handlePhone(props.formData, e)} />
            </Form.Group>
            </Card.Body>
        </Card>

        
        <p></p>

        {/* <div class="form-check">
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input" value="">Option 1</input>
        </label>
        </div>
        <div class="form-check">
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input" value="">Option 2</input>
        </label>
        </div>
        <div class="form-check">
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input" value="" disabled>Option 3</input>
        </label>
        </div> */}

        </div>
    );
}

function handlePhone(formData, e) {
    e.preventDefault();
    let value = e.target.value;
    formData.phone = value
    // this.setState({update: 'g'})
    document.getElementById('nextButton').disabled = (value === undefined ||
                                                      value === '' || value.length < 10)
}


export default ContactPhoneOnly;
