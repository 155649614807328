import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import * as consts from '../choices/formChoices';


function Purpose(props) {
    const nextPageId = "Purpose"

    const [purposeState, setPurposeState] = useState(
        Array(5).fill(false)
    );

    let updatePurposeData = (formData, index) => {
        var newPurposeState = purposeState.slice()
        newPurposeState[index] = !newPurposeState[index]
        
        // Clear out all inspection related choices
        if(index === 0 && newPurposeState[index] === false) {
            newPurposeState[4] = false
            newPurposeState[5] = false
            newPurposeState[6] = false
        }
        formData.purposeOptions = newPurposeState 
        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );

        setPurposeState(newPurposeState)
    }


    function handleSecondaryIssue(formData, e) {
        e.preventDefault();
        let value = e.target.value;
        formData.secondaryIssue = value
        // console.log("SETTING SECOND ISSUE: " + value)
        // this.setState({update: 'g'})   

        window.sessionStorage.setItem(
            "form_data",
            JSON.stringify(formData)
        );
    }
    useEffect(() => {

        var arrayLength = purposeState.length;
        
        let disableNextButton = true
        for (var i = 0; i < arrayLength; i++) {
            let item = purposeState[i]
            if(item === true) {
                disableNextButton = false
                break;
            }
        }

        window.sessionStorage.setItem("next_page", nextPageId)
        // document.getElementById('nextButton').disabled = true

        document.getElementById('submitButton').disabled = disableNextButton
    });
    
    return (
        <div>
        <h2><Badge variant="primary">Purpose</Badge></h2>
        <Card>
        <Card.Body>
        <Container>
            <Form.Group controlId="purpose">
                <Form.Label>Check all that apply.<div class="required_text">*required</div></Form.Label>
                <Form.Check 
                    type="checkbox"
                    id="purpose3"
                    label={consts.purposeData[3]}
                    checked={props.formData.purposeOptions[3] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 3);} }    
                />
                <Form.Check 
                    type="checkbox"
                    id="purpose0"
                    label={consts.purposeData[0]}
                    checked={props.formData.purposeOptions[0] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 0);} }    
                />
                {props.formData.purposeOptions[0] === true && 
                    <Row><Col xs={1}></Col><Col><Form.Check 
                    type="checkbox"
                    id="purpose4"
                    label={consts.purposeData[4]}
                    checked={props.formData.purposeOptions[4] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 4);} }
                    /></Col><Col></Col></Row>
                }

                {props.formData.purposeOptions[0] === true && 
                    <Row><Col xs={1}></Col><Col><Form.Check 
                    type="checkbox"
                    id="purpose5"
                    label={consts.purposeData[5]}
                    checked={props.formData.purposeOptions[5] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 5);} }
                    /></Col><Col></Col></Row>
                }

                {props.formData.purposeOptions[0] === true && 
                    <Row><Col xs={1}></Col><Col><Form.Check 
                    type="checkbox"
                    id="purpose6"
                    label={consts.purposeData[6]}
                    checked={props.formData.purposeOptions[6] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 6);} }
                    /></Col><Col></Col></Row>
                }

                <Form.Check 
                    type="checkbox"
                    id="purpose1"
                    label={consts.purposeData[1]}
                    checked={props.formData.purposeOptions[1] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 1);} }
                />
                <Form.Check 
                    type="checkbox"
                    id="purpose2"
                    label={consts.purposeData[2]}
                    checked={props.formData.purposeOptions[2] === true}
                    onClick={function(e) {updatePurposeData(props.formData, 2);} }
                />

            </Form.Group>
            </Container>
        </Card.Body>
        </Card>


        <p></p>

        <Card>
        <Card.Body>
        <Form.Group controlId="secndaryissue">
            <Form.Label>Please type any other issues you’d like us to look at, a general budget, and we can discuss it when we reach
out to you.</Form.Label>
            <Form.Control type="text" placeholder="Your answer" defaultValue={props.formData.secondaryIssue} onChange={(e) => handleSecondaryIssue(props.formData, e)}/>
        </Form.Group>
        </Card.Body>
        </Card>
        </div>
    )
}


export default Purpose;












